import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IAlbum, IEvent, Lightbox, LIGHTBOX_EVENT, LightboxConfig, LightboxEvent} from 'ngx-lightbox';
import {Subscription} from 'rxjs';
import {AdvLightBoxService} from '../../../../adv-light-box/adv-light-box.service';
// @ts-ignore
import moment from 'moment';
import axios from 'axios';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input() albums: Array<IAlbum>;
  // @ts-ignore
  @ViewChild('newPostModal') newPostModal: any;

  private subscription: Subscription;
  private newImage: any;
  public maskDateHour = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ' ', 'a', 't', ' ', /\d/, /\d/, ':', /\d/, /\d/];
  private image: any;

  constructor(private lightbox: Lightbox, private lightboxEvent: LightboxEvent,
              private lighboxConfig: LightboxConfig, private advLightBoxService: AdvLightBoxService) {
    lighboxConfig.fadeDuration = 1;
    AdvLightBoxService.getNewPostModal = () => this.newPostModal;
    this.newEmptyImage();
  }

  private newEmptyImage() {
    const newDate = moment().add(1, 'days');
    this.newImage = {
      wrongImageDate: false,
      description: '',
      publicationDate: newDate,
      publicationDateStr: newDate.format('DD.MM.YYYY[, at ]HH:mm'),
      changingImage: false,
      src: false,
    };
  }

  ngOnInit() {
  }

  filterAlbums(albums) {
    return albums.filter(item => !item.removed);
  }

  open(index: number): void {
    this.subscription = this.lightboxEvent.lightboxEvent$.subscribe((event: IEvent) => this._onReceivedEvent(event));
    this.lightbox.open(this.albums, index, { wrapAround: true, showImageNumberLabel: true });
  }

  private _onReceivedEvent(event: IEvent): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this.subscription.unsubscribe();
    }
  }

  public onNewImageDateChange(event) {
    const newDate = moment(event.target.value, 'DD.MM.YYYY[, at ]HH:mm');
    this.newImage.wrongPublicationDate = newDate.format() === 'Invalid date';
    this.newImage.publicationDate = newDate;
    this.newImage.publicationDateStr = this.newImage.wrongPublicationDate ?
      event.target.value : newDate.format('DD.MM.YYYY[, at ]HH:mm');
  }

  public onDateChange(event) {
    const newDate = moment(event.target.value, 'DD.MM.YYYY[, at ]HH:mm');
    this.image.wrongPublicationDate = newDate.format() === 'Invalid date';
    this.image.publicationDate = newDate;
    this.image.publicationDateStr = this.image.wrongPublicationDate ?
      event.target.value : newDate.format('DD.MM.YYYY[, at ]HH:mm');
  }

  public changeImage(fileInput) {
    fileInput.click();
  }

  public changeFileInput($event, isNewImage = false) {
    const file = $event.target.files;
    if (file.length > 0 && ((/\.(gif|jpg|jpeg|tiff|png)$/i).test(file[0].name))) {
      if (isNewImage) {
        this.newImage.changingImage = true;
      } else {
        this.image.changingImage = true;
      }
      const formData = new FormData();
      formData.append('file', file[0]);
      axios.post('/api/image-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(({ data }) => {
        if ('error' in data) {
          alert('An unexpected error occurred');
        } else {
          if (isNewImage) {
            this.newImage.src = data.success.Location;
            this.newImage.changingImage = false;
          } else {
            this.image.src = data.success.Location;
            this.image.changingImage = false;
          }
        }
      });
    } else if (file.length > 0) {
      alert('The file you choose is not a compatible image file.');
    }
  }

  public showModal(modal, image) {
    this.image = image;
    modal.show();
  }

  public hideModal(modal, updateChanges = true) {
    if (updateChanges) {
      this.image.oldDescription = this.image.description;
      this.image.oldPublicationDate = this.image.publicationDate;
      this.image.publicationDateStr = moment(this.image.publicationDate).format('DD.MM.YYYY[, at ]HH:mm');
      this.image.oldSrc = this.image.src;

      this.advLightBoxService.savePublicationChanges(this.image);
    } else {
      this.image.description = this.image.oldDescription;
      this.image.publicationDate = this.image.oldPublicationDate;
      this.image.publicationDateStr = moment(this.image.oldPublicationDate).format('DD.MM.YYYY[, at ]HH:mm');
      this.image.src = this.image.oldSrc;
      this.image.wrongPublicationDate = false;
    }
    modal.hide();
  }

  public deletePublication(modal) {
    modal.hide();
    this.image.removed = true;
    this.advLightBoxService.deletePublication(this.image);
  }
}
