import axios from 'axios';
// @ts-ignore
import moment from 'moment';

export class AdvLightBoxService {
  public static getNewPostModal: any;

  constructor() {
    this.albumsLoading = true;
    axios.get('/api/quotes-to-post').then(({ data }) => this.refreshQuotes(data));
  }
  public albums: any[];
  public albumsLoading: boolean;

  public refreshQuotes(data) {
    if ('error' in data) {
      console.error(data.error);
    } else {
      this.albums = data.map(item => ({
        id: item.id,
        src: item.imageUrl,
        oldSrc: item.imageUrl,
        publicationDate: moment(item.publicationDate),
        publicationDateStr: moment(item.publicationDate).format('DD.MM.YYYY[, at ]HH:mm'),
        oldPublicationDate: moment(item.publicationDate),
        description: item.description,
        oldDescription: item.description,
        removed: false,
        wrongPublicationDate: false,
        changingImage: false
      }));
      this.albumsLoading = false;
    }
  }

  public savePublicationChanges(image: any) {
    const data = {
      id: image.id,
      imageUrl: image.src,
      publicationDate: image.publicationDate.format(),
      description: image.description
    };

    axios.post('/api/update-quote', data).then(() => {
      console.log('quote updated');
    });
  }

  public newPublication(image: any) {
    const newImage = {
      imageUrl: image.src,
      publicationDate: image.publicationDate.format(),
      description: image.description
    };
    axios.post('/api/new-quote', newImage).then(({ data }) => this.refreshQuotes(data));
  }

  public deletePublication(image: any) {
    axios.post('/api/delete-quote', { quoteId: image.id }).then(() => {
      console.log('quote deleted');
    });
  }
}
